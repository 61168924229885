import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid, Box, Typography } from '@mui/material';
import { Tooltip } from '../../../../../components';
import { TariffStep } from './TariffStep';
import i18n from '../../../../../i18n';
import {
  tariffTypes,
  defaultStepsForSeasons,
  unitsTypesByCountry,
  PROVIDERS_LIST_ID,
  directMarketing
} from '../../../constants';
import { APP_ID, HELION_APP_ID, SOLAR_MANAGER_APP_ID } from '../../../../../config';

const getTariffLabel = ({ label, tooltip, value, valueSx = {}, isRender = true }) => (isRender ? (
  <Fragment key={label}>
    <Grid item className="list" sx={{ minWidth: 'max-content' }} xs={1}>
      <h4 className="list-item-title general-tab-item-title">
        {i18n.t(label)}
        {tooltip && <Tooltip title={i18n.t(tooltip)} />}
        :
      </h4>
    </Grid>
    <Grid
      item
      className="list"
      sx={{ minWidth: 'max-content', textTransform: 'capitalize', ...valueSx }}
      xs={2}
    >
      {value}
    </Grid>
  </Fragment>
) : null);

const getDoubleTariff = (settings) => {
  const currentSeason = 'commonSeasons'; // TODO: we can add tje logic for winter season here

  return (
    <>
      <Grid
        columnSpacing={4}
        container
        spacing={1}
        item
        className="list"
        sx={{ minWidth: 'max-content' }}
        columns={2}
        xs={2}
      >
        <Grid item className="list" sx={{ minWidth: 'max-content' }} xs={1}>
          <Box sx={{ display: 'flex', columnGap: 3 }}>
            <Typography variant="h4" sx={{ color: 'rgba(255, 255, 255, 0.50)' }}>
              {i18n.t('tariffType')}
              <Tooltip title={i18n.t('tariffTypeTooltip')} />
              :
            </Typography>
            <Typography variant="h4" sx={{ color: 'secondary' }}>
              {`${i18n.t('double')}`}
            </Typography>
          </Box>
        </Grid>
        <Grid item className="list" xs={2} lg={1}>
          <Box sx={{ display: 'flex', columnGap: 3 }}>
            <Typography variant="h4" sx={{ color: 'rgba(255, 255, 255, 0.50)' }}>
              {i18n.t('unit')}
              :
            </Typography>
            <Typography variant="h4" sx={{ color: 'secondary.main' }}>
              {i18n.exists(settings.unit) ? i18n.t(settings.unit) : settings.unit}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid item className="list" sx={{ minWidth: '300px' }} xs={2} lg={1}>
        <Typography variant="body1" sx={{ color: 'secondary.stepColor' }}>
          {i18n.t('mondayFriday')}
        </Typography>
        <TariffStep
          lowTariff={settings.lowTariff}
          highTariff={settings.highTariff}
          steps={settings[currentSeason]?.mondayFriday || []}
          styles={{ padding: '4px 6px', fontSize: 12, height: 24 }}
        />
      </Grid>
      <Grid item className="list" sx={{ minWidth: '300px' }} xs={2} lg={1}>
        <Typography variant="body1" sx={{ color: 'secondary.stepColor' }}>
          {i18n.t('saturday')}
        </Typography>
        <TariffStep
          lowTariff={settings?.lowTariff}
          highTariff={settings.highTariff}
          steps={settings[currentSeason]?.saturday || []}
          styles={{ padding: '4px 6px', fontSize: 12, height: 24 }}
        />
      </Grid>
      <Grid item className="list" sx={{ minWidth: '300px' }} xs={2} lg={1}>
        <Typography variant="body1" sx={{ color: 'secondary.stepColor' }}>
          {i18n.t('sunday')}
        </Typography>
        <TariffStep
          lowTariff={settings.lowTariff}
          highTariff={settings.highTariff}
          steps={settings[currentSeason]?.sunday || []}
          styles={{ padding: '4px 6px', fontSize: 12, height: 24 }}
        />
      </Grid>
    </>
  );
};

export const TariffSection = ({ user = {}, myRoleType = '' }) => {
  const { settings = {}, country } = user;
  const providers = useSelector((state) => state.dataList[PROVIDERS_LIST_ID]?.data || []);
  const provider = providers.find(({ value }) => value === settings.provider)?.label || settings.provider;
  const isHelionUser = user?.company?.name.includes('Helion');
  const isRenderDirectMarketingField = (APP_ID === HELION_APP_ID && !['end_user', 'sub_user'].includes(myRoleType))
    || (APP_ID === SOLAR_MANAGER_APP_ID && ['root', 'solar_admin'].includes(myRoleType));

  switch (settings.tariffType) {
    case tariffTypes.single: {
      const data = [
        {
          value: i18n.t('single'),
          tooltip: 'tariffTypeTooltip',
          label: 'tariffType'
        },
        {
          label: i18n.t('unit'),
          value: unitsTypesByCountry[country?.toLowerCase()] || unitsTypesByCountry.default,
          valueSx: { textTransform: 'none' }
        },
        {
          label: i18n.t('price'),
          value: settings.singleTariff
        },
        {
          label: 'directMarketing',
          value:
            i18n.t(directMarketing[settings.directMarketing]?.label)
            || i18n.t(directMarketing[0].label),
          tooltip: 'directMarketingTooltip',
          isRender: isHelionUser && isRenderDirectMarketingField
        },
        {
          label: i18n.t('exportPrice'),
          value: settings.exportTariffs?.standard?.price,
          isRender: isHelionUser && isRenderDirectMarketingField && settings.directMarketing === 0
        }
      ];
      return (
        <Grid columns={3} container spacing={1}>
          {data.map(getTariffLabel)}
        </Grid>
      );
    }
    case tariffTypes.double: {
      return (
        <>
          <Grid columns={2} columnSpacing={4} container spacing={1}>
            {getDoubleTariff({
              ...defaultStepsForSeasons,
              ...settings,
              unit: unitsTypesByCountry[country?.toLowerCase()] || unitsTypesByCountry.default
            })}
          </Grid>
          {isHelionUser && isRenderDirectMarketingField && (
            <Grid columns={3} container spacing={1} sx={{ marginTop: '15px', minWidth: 'max-content' }}>
              {getTariffLabel({
                label: 'directMarketing',
                value:
                  i18n.t(directMarketing[settings.directMarketing]?.label)
                  || i18n.t(directMarketing[0].label),
                tooltip: 'directMarketingTooltip'
              })}
              {isHelionUser && isRenderDirectMarketingField && settings.directMarketing === 0 && (
                <>
                  {getTariffLabel({
                    label: i18n.t('unit'),
                    value: unitsTypesByCountry[country?.toLowerCase()] || unitsTypesByCountry.default,
                    valueSx: { textTransform: 'none' }
                  })}

                  {getTariffLabel({
                    label: i18n.t('exportPrice'),
                    value: settings.exportTariffs?.standard?.price
                  })}

                </>
              )}
            </Grid>
          )}
        </>
      );
    }

    case tariffTypes.dynamic: {
      const data = [
        {
          value: `${i18n.t('dynamic')}`,
          tooltip: 'tariffTypeTooltip',
          label: 'tariffType'
        },
        { label: 'provider', value: provider || 'provider' },
        {
          label: i18n.t('meteringCode'),
          value: settings.meteringCode,
          isRender: settings.providerType === 'meteringCode'
        },
        {
          label: 'directMarketing',
          value: i18n.t(directMarketing[settings.directMarketing]?.label || directMarketing[0].label),
          tooltip: 'directMarketingTooltip',
          isRender: isHelionUser && isRenderDirectMarketingField
        },
        {
          label: i18n.t('unit'),
          value: unitsTypesByCountry[country?.toLowerCase()] || unitsTypesByCountry.default,
          valueSx: { textTransform: 'none' },
          isRender: isHelionUser && isRenderDirectMarketingField && settings.directMarketing === 0
        },
        {
          label: i18n.t('exportPrice'),
          value: settings.exportTariffs?.standard?.price,
          isRender: isHelionUser && isRenderDirectMarketingField && settings.directMarketing === 0
        }
      ];

      return (
        <Grid columns={3} container spacing={1}>
          {data.map(getTariffLabel)}
        </Grid>
      );
    }
    default:
      return null;
  }
};

TariffSection.propTypes = {
  user: PropTypes.instanceOf(Object),
  myRoleType: PropTypes.string
};
